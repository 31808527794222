import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import Config from "../config";

import { 
    useMoralis
} from "react-moralis";

export default function useUserInfo(SCM, SCMInterface, provider, address, scmPrice) {
    const { Moralis } = useMoralis();
    const moralis_TransferObject = Moralis.Object.extend(Config.MORALIS.TABLE_NAMES.Transfers);
    const moralis_BuysQuery = new Moralis.Query(moralis_TransferObject);
    const moralis_SellsQuery = new Moralis.Query(moralis_TransferObject)

    const [balance, setBalance] = useState();
    const [scmBought, setScmBought] = useState();    
    const [scmSold, setScmSold] = useState();

    const [scmEarned, setScmEarned] = useState();
    const [usdEarned, setUsdEarned] = useState();

    const [apy, setApy] = useState();
    const [dailyEarnings, setDailyEarnings] = useState();

    async function getBalance() {
        try {
            let _balance = await SCM.balanceOf(address);
            setBalance(_balance)
            return;
        } catch (e) {
            console.log(e)
        }
        setBalance(ethers.BigNumber.from(0))
        return;
    }

    async function getScmBought() {
        try {
            if (scmBought === undefined) {
                let buys = await moralis_BuysQuery.find();
                if (buys.length > 0) {
                    setScmBought(buys.reduce((acc, v) => {
                        let value = v.get(Config.MORALIS.TABLE_COLUMNS.Buys.value);                   
                        return acc.add(value)
                    }, ethers.BigNumber.from(0)));
                } else {
                    setScmBought(ethers.BigNumber.from(0))
                }                
            }
            return;
        } catch (e) {
            console.log(e)
        }
        setScmBought(ethers.BigNumber.from(0))
        return;
    }


    async function getScmSold() {
        try {
            if (scmSold === undefined) {
                let sells = await moralis_SellsQuery.find();

                if (sells.length > 0) {
                    setScmSold(sells.reduce((acc, v) => {
                        let value = v.get(Config.MORALIS.TABLE_COLUMNS.Sells.value);                   
                        return acc.add(value)
                    }, ethers.BigNumber.from(0)).mul(119).div(100));
                } else {
                    setScmSold(ethers.BigNumber.from(0))
                }                
            }
            return;
        } catch (e) {
            console.log(e)
        }
        setScmSold(ethers.BigNumber.from(0))
        return;
    }

    useEffect(() => {
        if (balance !== undefined &&
            scmBought !== undefined &&
            scmSold !== undefined &&
            scmPrice !== undefined &&
            scmBought.gt(0)) {
                let _earned = balance.add(scmSold).sub(scmBought);
                setScmEarned(_earned);
                setUsdEarned(ethers.BigNumber.from(Math.floor(_earned.toNumber() * scmPrice)));

                let _apy = _earned.mul(100).div(scmBought);
                setApy(_apy);

                let _dailyEarnings = balance.mul(10215).div(10000);
                _dailyEarnings = _dailyEarnings.sub(balance);
                setDailyEarnings(ethers.BigNumber.from(Math.floor(_dailyEarnings.toNumber() * scmPrice)));
            }

    }, [balance, scmBought, scmSold, scmPrice])
    
    async function update() {
        if (SCM && SCMInterface && address) {
            await Promise.all([
                getBalance(),
                getScmBought(),
                getScmSold()                           
            ])
        }
    }    

    useEffect(() => {
        if (provider && SCM && SCMInterface && address) {
            update();
            provider.on("block", _ => {                
                update();
            })
            return () => provider.off("block");
        }
    }, [provider, SCM, SCMInterface, address]);

    useEffect(() => {
        if (address) {
            moralis_BuysQuery.equalTo(Config.MORALIS.TABLE_COLUMNS.Buys.to, address.toLowerCase());
            moralis_BuysQuery.limit(100000000000);

            moralis_SellsQuery.equalTo(Config.MORALIS.TABLE_COLUMNS.Sells.from, address.toLowerCase());
            moralis_SellsQuery.limit(100000000000);
        }
    }, [address]);

    return [
        usdEarned,
        scmEarned,
        apy,
        balance,
        dailyEarnings
    ]
}