import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { MoralisProvider } from "react-moralis";
import Config from "./config"

const random = Math.floor(Math.random() * 100 % Config.MORALIS.CREDS.length);
console.log(`CONNECTED TO SERVER ${random}`)
let appId = Config.MORALIS.CREDS[random].APP_ID;
let serverUrl = Config.MORALIS.CREDS[random].SERVER_URL;

ReactDOM.render(
    <React.StrictMode>
      <MoralisProvider appId={appId} serverUrl={serverUrl}>
          <App />
      </MoralisProvider>, 
    </React.StrictMode>,
    document.getElementById('root')
  );