
// Formats a string representing a number 
export const formatNumber = (str) => {
    if (!str || str === "" || typeof str !== "string")
        return "0";

    let commaSplit = str.includes(".") ? str.split(".") : str.split(",");
    let roundNum = commaSplit[0];

    // number between 0 and 999
    if (roundNum.length < 4) {         
        if (commaSplit.length > 1) {
            let commaPart = commaSplit[1].slice(0,2);
            return roundNum + "." + commaPart;
        } else {
            return roundNum;
        }
    } // 1000 - 999.999
    else if (roundNum.length >= 4 && roundNum.length < 7) {
        let hundredAmount = roundNum.substr(roundNum.length - 3);
        let thousandAmount = roundNum.slice(0, -3);

        if (parseInt(hundredAmount) > 9) {
            return thousandAmount + "." + hundredAmount.slice(0,2) + "K";
        } else {
            return thousandAmount + "K"
        }
    } // 1.000.000 - 999.999.999
    else if (roundNum.length >= 7 && roundNum.length < 10) {
        let thousandAmount = roundNum.substr(roundNum.length - 6);
        let milionAmount = roundNum.slice(0, -6);

        if (parseInt(thousandAmount) > 9999) {
            return milionAmount + "." + thousandAmount.slice(0,2) + "M";
        } else {
            return milionAmount + "M"
        }
    } // 1.000.000.000 - 999.999.999.999
    else if (roundNum.length >= 10 /*&& roundNum.length < 13*/) {
        let milionAmount = roundNum.substr(roundNum.length - 9);
        let bilionAmount = roundNum.slice(0, -9);

        if (parseInt(milionAmount) > 9999999) {
            return bilionAmount + "." + bilionAmount.slice(0,2) + "B";
        } else {
            return bilionAmount + "B"
        }
    }
}

// Formats number seconds to countdown string
export const formatSeconds = (secs) => {
    const sec = 1;
    const min = sec * 60;
    const hr = min * 60;
    const day = hr * 24;

    let textDay = Math.floor(secs / day);
    let textHour = Math.floor((secs % day) / hr);
    let textMin = Math.floor((secs % hr) / min);
    let textSec = Math.floor((secs % min) / sec);

    if (textDay < 10) {
        textDay = "0" + textDay;
    }

    if (textHour < 10) {
        textHour = "0" + textHour;
    }

    if (textMin < 10) {
        textMin = "0" + textMin;
    }

    if (textSec < 10) {
        textSec = "0" + textSec;
    }

    return "00:" + textMin + ":" + textSec
}

export const checkPositiveNumber = (num) => {
    let parsed = parseFloat(num);
    if (isNaN(parsed)) {
        return false;
    } else {
        return parsed >= 0
    }
}