import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Index from "./pages/index.jsx"

import { 
    useMoralis
} from "react-moralis";


function App() {
    const { isInitialized } = useMoralis()   

  return (
    <div>
        {
            isInitialized &&
            <Index />
        }
    </div>
  );
}

export default App;
