import { useState, useEffect } from 'react';

// input:
// scm initial amount
// scm initial price
// end date
// end price

// formula:
// calculate end amount using rebase
// calculate end value using end price
// calculate begin value using begin price and amount

// show
// initial usd investment
// current wealth amount
// scm earned from rebase
// return from current usd to begin usd
export default function useCalculator() {
    const [initial, setInitial] = useState();
    const [current, setCurrent] = useState();
    const [earned, setEarned] = useState();
    const [apy, setApy] = useState();

    function calculateScenario(initial_amount, initial_price, time_delta, end_price) {
        let _initial = initial_amount * initial_price;
        let _earned = (initial_amount * 1.0215**time_delta) - initial_amount;
        let _current = (initial_amount + _earned) * end_price;
        let _apy = _earned - _initial;

        setInitial(_initial)
        setCurrent(_current)
        setEarned(_earned)
        setApy(_apy);
    }

    return [
        initial,
        current,
        earned,
        apy,
        calculateScenario
    ]
}