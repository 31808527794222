import React, { useState } from "react";
import { formatNumber, formatSeconds } from "../utils";

export default function Dashboard ({
    price,
    priceChange,
    circulatingSupply,
    marketCap,
    lastRebaseTime,
    backedLiquidity,
    bnbLiquidityValue,
    bnbLiquidityValueChange,
    treasuryValue,
    treasuryValueChange,
    lfvValue,
    lfvValueChange,
    timeUntilRebase
}) {
    return (
        <>
            <div id="w-node-_8aa8924f-d6e6-432d-5125-fc761c2ce669-c6421247" className="dashboard-content">
                <div className="block dashboard">
                    <div className="w-row">
                    <div className="w-col w-col-4">
                        <div className="container w-container">
                        <div className="body-p dahsboard">Market Cap</div>
                        <div className="body-h4">${marketCap ? formatNumber(marketCap.toString()) : "0.000"}<br/></div>
                        </div>
                        <div className="container w-container">
                        <div className="body-p dahsboard">Circulating Supply</div>
                        <div className="body-h4">{circulatingSupply ? formatNumber(circulatingSupply.toString()) : "0.000"}<br/></div>
                        </div>
                    </div>
                    <div className="w-col w-col-4">
                        <div className="container w-container">
                        <div className="body-p dahsboard">SCM Price</div>
                        <div className="body-h4">${price ? formatNumber(price.toString()) : "0.000"}<br/></div>
                        </div>
                        <div className="container w-container">
                        <div className="body-p dahsboard">Backed Liquidity</div>
                        <div className="body-h4">{backedLiquidity ? formatNumber(backedLiquidity.toString()) : "0.00"}%<br/></div>
                        </div>
                    </div>
                    {<div className="w-col w-col-4">
                        <div className="container w-container">
                        <div className="body-p dahsboard">Next Rebase</div>
                        <div className="body-h4">{timeUntilRebase ? formatSeconds(timeUntilRebase) : "00:00:00"}<br/></div>
                        </div>
                        {/*<div className="container w-container">
                        <div className="body-p dahsboard">Average SCM Holding</div>
                        <div className="body-h4">$0,000.00<br/></div>
                        </div>*/}
                    </div>}
                    </div>
                </div>
                <div className="w-layout-grid grid">
                    <div id="w-node-f029b93e-4b46-77dc-ae9e-7ed730ca3692-c6421247" className="block dashboard">                        
                    <div className="container w-container">
                        <div className="w-row">
                        <div className="column-26 w-col w-col-8 w-col-small-6">
                            <div className="body-p dahsboard">SCM Price</div>
                        </div>
                        {
                            priceChange &&
                            <div className="w-col w-col-4 w-col-small-6">
                                <div className="body-p dahsboard pricechange">{formatNumber(priceChange.toString())}%</div>
                            </div>
                        }
                        </div>
                        <div className="body-h4">${price ? formatNumber(price.toString()) : "0.000"}<br/></div>
                    </div>
                    </div>

                    <div id="w-node-fed05b9f-88d6-5d4d-eb58-7bb4bd56591c-c6421247" className="block dashboard">
                    <div className="container w-container">
                        <div className="w-row">
                        <div className="column-27 w-col w-col-8 w-col-small-8">
                            <div className="body-p dahsboard">Market Value Of Treasury Assets</div>
                        </div>
                        {
                            treasuryValueChange &&
                            <div className="w-col w-col-4 w-col-small-4">
                                <div className="body-p dahsboard pricechange">+0.00%</div>
                            </div>
                        }
                        </div>
                        <div className="body-h4">${treasuryValue ? formatNumber(treasuryValue) : "0.000"}<br/></div>
                    </div>
                    </div>

                    <div id="w-node-_0171b0f2-62fa-d378-8665-5cbfe492d068-c6421247" className="block dashboard">
                    <div className="container w-container">
                        <div className="w-row">
                        <div className="column-28 w-col w-col-8 w-col-small-8">
                            <div className="body-p dahsboard">BNB Liquidity Value</div>
                        </div>
                        {
                            bnbLiquidityValueChange &&
                            <div className="w-col w-col-4 w-col-small-4">
                                <div className="body-p dahsboard pricechange">+0.00%</div>
                            </div>
                        }
                        </div>
                        <div className="body-h4">${bnbLiquidityValue ? formatNumber(bnbLiquidityValue) : "0.000"}<br/></div>
                    </div>
                    </div>

                    <div id="w-node-_08aeed6a-ce1d-fa96-0998-3c855e5c031b-c6421247" className="block dashboard">
                    <div className="container w-container">
                        <div className="w-row">
                        <div className="column-29 w-col w-col-8 w-col-small-8">
                            <div className="body-p dahsboard">Risk Free Value Market Value</div>
                        </div>
                        {
                            lfvValueChange &&
                            <div className="w-col w-col-4 w-col-small-4">
                                <div className="body-p dahsboard pricechange">+0.00%</div>
                            </div>
                        }
                        </div>
                        <div className="body-h4">${lfvValue ? formatNumber(lfvValue) : "0.000"}<br/></div>
                    </div>
                    </div>
                </div>
            </div>
        </>        
    )
}