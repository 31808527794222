import React, { useState, useEffect } from "react";
import { checkPositiveNumber, formatNumber } from "../utils";
import { ethers } from "ethers"

export default function Calculator ({
    price,
    balance,
    initial,
    current,
    earned,
    c_apy,
    calculateScenario
}) {
    const [scmAmount, setScmAmount] = useState(0);
    const [scmInitialPrice, setScmInitialPrice] = useState(0);
    const [scmFuturePrice, setScmFuturePrice] = useState(0);
    const [days, setDays] = useState(0);

    useEffect(() => {
        if (!isNaN(scmAmount) && !isNaN(scmInitialPrice) && !isNaN(days) && !isNaN(scmFuturePrice)) {
            calculateScenario(scmAmount, scmInitialPrice, days, scmFuturePrice)
        }   
    }, [scmAmount, scmInitialPrice, scmFuturePrice, days])

    return (
        <>
            <div id="w-node-_8aa8924f-d6e6-432d-5125-fc761c2ce669-5f5ecd09" className="dashboard-content">
                <div className="block dashboard">
                    <div className="container w-container">
                    <div className="sub-text"><strong>Calculator</strong></div>
                    <div className="body-p dahsboard">Estimate your returns<br/></div>
                    </div>
                    <div className="w-row">
                    <div className="w-col w-col-4 w-col-stack">
                        <div className="container w-container">
                        <div className="body-p dahsboard">SCM Price</div>
                        <div className="body-h4">${price ? formatNumber(price.toString()) : "0.000"}<br/></div>
                        </div>
                    </div>
                    <div className="w-col w-col-4 w-col-stack">
                        <div className="container w-container">
                        <div className="body-p dahsboard">Daily Reward</div>
                        <div className="body-h4">2.15 %<br/></div>
                        </div>
                    </div>
                    <div className="w-col w-col-4 w-col-stack">
                        <div className="container w-container">
                        <div className="body-p dahsboard">Your SCM Holdings</div>
                        <div className="body-h4">{balance ? formatNumber(ethers.utils.formatUnits(balance, 5)) : "0.0000"}<br/></div>
                        </div>
                    </div>
                    </div>
                    <div className="w-row">
                    <div className="w-col w-col-6">
                        <div className="body-p dahsboard">SCM Amount ($)</div>
                        <div className="block dashboard imputfield w-container">
                        <div className="w-row">
                            <div className="w-col w-col-8 w-col-small-8 w-col-tiny-8">
                            <input 
                                className="body-p dahsboard inputfield calculator-input" 
                                placeholder="0.00"
                                type="number"
                                min="0"
                                onChange={(e) => {
                                    let num = e.target.value;
                                    if (checkPositiveNumber(num) || e.target.value === "")
                                        setScmAmount(parseFloat(num))
                                }}
                                value={scmAmount}
                            />
                            </div>
                            <div className="w-col w-col-4 w-col-small-4 w-col-tiny-4">                            
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="w-col w-col-6">
                        <div className="body-p dahsboard">SCM price at purchase ($)</div>
                        <div className="block dashboard imputfield w-container">
                        <div className="w-row">
                            <div className="w-col w-col-8 w-col-small-8 w-col-tiny-8">
                            <input 
                                className="body-p dahsboard inputfield calculator-input" 
                                placeholder="0.00"
                                type="number"
                                min="0"
                                onChange={(e) => {
                                    let num = e.target.value;
                                    if (checkPositiveNumber(num) || e.target.value === "")
                                        setScmInitialPrice(parseFloat(num))
                                }}
                                value={scmInitialPrice}
                            />
                            </div>
                            <div className="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                            <div className="body-p dahsboard inputfield button" onClick={() => setScmInitialPrice(parseFloat(price))}>Current</div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="columns-8 w-row">
                    <div className="w-col w-col-6">
                        <div className="body-p dahsboard">Future SCM price ($)</div>
                        <div className="block dashboard imputfield w-container">
                        <div className="w-row">
                            <div className="w-col w-col-8 w-col-small-8 w-col-tiny-8">
                            <input 
                                className="body-p dahsboard inputfield calculator-input" 
                                placeholder="0.00"
                                type="number"
                                min="0"
                                onChange={(e) => {
                                    let num = e.target.value;
                                    if (checkPositiveNumber(num) || e.target.value === "")
                                        setScmFuturePrice(parseFloat(num))
                                }}
                                value={scmFuturePrice}
                            />
                            </div>
                            <div className="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                            <div className="body-p dahsboard inputfield button" onClick={() => setScmFuturePrice(parseFloat(price))}>Current</div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="w-col w-col-6">
                        <div className="body-p dahsboard">Days held</div>
                        <div className="block dashboard imputfield w-container">
                        <input 
                                className="body-p dahsboard inputfield calculator-input" 
                                placeholder="0"
                                type="number"
                                min="0"
                                onChange={(e) => {
                                    let num = e.target.value;
                                    if (checkPositiveNumber(num) || e.target.value === "")
                                        setDays(Math.floor(parseFloat(num)))
                                }}
                                value={days}
                            />
                        </div>
                    </div>
                    </div>
                    <div className="div-block-8">
                    <div className="block dashboard imputfield w-container">
                        <div className="w-row">
                        <div className="w-col w-col-8 w-col-small-8">
                            <div className="body-p dahsboard">Your initial investment</div>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4">
                            <div className="body-p dahsboard right">${initial ? formatNumber(initial.toString()): "0.00"}</div>
                        </div>
                        </div>
                        <div className="w-row">
                        <div className="w-col w-col-8 w-col-small-8">
                            <div className="body-p dahsboard">Current wealth</div>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4">
                            <div className="body-p dahsboard right">${current ? formatNumber(current.toString()): "0.00"}</div>
                        </div>
                        </div>
                        <div className="w-row">
                        <div className="w-col w-col-8 w-col-small-8">
                            <div className="body-p dahsboard">SCM rewards estimation<br/></div>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4">
                            <div className="body-p dahsboard right">{earned ? formatNumber(earned.toString()): "0.00"} SCM</div>
                        </div>
                        </div>
                        <div className="w-row">
                        <div className="w-col w-col-8 w-col-small-8">
                            <div className="body-p dahsboard">Potential return</div>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4">
                            <div className="body-p dahsboard right">${c_apy ? formatNumber(c_apy < 0 ? c_apy : c_apy.toString()): "0.00"}</div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>        
    )
}