import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import axios from "axios";
import useClock from "./useClock"

export default function useSCMInfo(SCM, WBNB, provider) {
    const [price, setPrice] = useState();
    const [priceChange, setPriceChange] = useState(); // not done yet

    const [bnbUSD, setBNBUSD] = useState();

    const [circulatingSupply, setCirculatingSupply] = useState();
    const [marketCap, setMarketCap] = useState();    
    const [backedLiquidity, setBackedLiquidity] = useState();

    const [lastRebaseTime, setLastRebaseTime] = useState();
    const [rebaseRate, setRebaseRate] = useState();
    const [timeUntilRebase, setTimeUntilRebase] = useState();

    const [bnbLiquidityValue, setBNBLiquidityValue] = useState(); // not accurate
    const [bnbLiquidityValueChange, setBNBLiquidityValueChange] = useState();

    const [treasuryValue, setTreasuryValue] = useState();
    const [treasuryValueChange, setTreasuryValueChange] = useState();

    const [lfvValue, setLfvValue] = useState();
    const [lfvValueChange, setLfvValueChange] = useState();

    async function getPrice() {
        try {
            let pancakeResponse = await axios.get("https://api.pancakeswap.info/api/v2/tokens/0xD0f30CdB663656bbDc57f4C71964FD44966D59FA");
            if (pancakeResponse && pancakeResponse.data && pancakeResponse.data.data && pancakeResponse.data.data.price) {
                setPrice(parseFloat(pancakeResponse.data.data.price))
                return;
            }
        } catch (e) {
            console.log(e)
        }
        setPrice(ethers.BigNumber.from(0))
        return;
    }

    async function getBNBUSD() {
        try {
            let binanceResponse = await axios.get("https://api.binance.com/api/v1/ticker/24hr?symbol=BNBUSDT");               
            if (binanceResponse && binanceResponse.data && binanceResponse.data.lastPrice) {
                setBNBUSD(ethers.BigNumber.from(Math.floor(parseFloat(binanceResponse.data.lastPrice))))
                return;
            }
        } catch (e) {
            console.log(e)
        }
        setBNBUSD(ethers.BigNumber.from(0))
        return;
    }

    async function getCirculatingSupply() {
        try {
            let _circulating = await SCM.getCirculatingSupply();
            setCirculatingSupply(_circulating.div(100000));
            return;
        } catch(e) {
            console.log(e);
        }

        setCirculatingSupply(ethers.BigNumber.from(0));
        return
    }

    async function getLastRebaseTime() {
        try {
            let _lastTime = await SCM._lastRebasedTime();
            setLastRebaseTime(_lastTime);
            return;
        } catch(e) {
            console.log(e);
        }

        setLastRebaseTime(ethers.BigNumber.from(0));
        return
    }

    async function getRebaseRate() {
        try {
            let _rate = await SCM.rebaseRate();
            setRebaseRate(_rate);
            return;
        } catch(e) {
            console.log(e);
        }

        setRebaseRate(ethers.BigNumber.from(1));
        return
    }

    async function getLiquidityBacking() {
        try {
            let _backing = await SCM.getLiquidityBacking(100);           
            setBackedLiquidity(_backing);
            return;
        } catch(e) {
            console.log(e);
        }

        setBackedLiquidity(ethers.BigNumber.from(0));
        return
    }

    async function getBNBLiquidityValue() {
        try {
            let liqBNB = await WBNB.balanceOf("0x2ceac0a94b2f7b348c8db97d16b6a6527f844369");
            setBNBLiquidityValue(ethers.utils.formatEther(liqBNB.mul(bnbUSD)));
            return;
        } catch(e) {
            console.log(e);
        }

        setBNBLiquidityValue("0");
        return
    }

    async function getTreasuryValue() {
        try {
            let treasury = await SCM.treasury();
            let liqBNB = await provider.getBalance(treasury);
            setTreasuryValue(ethers.utils.formatEther(liqBNB.mul(bnbUSD)));
            return;
        } catch(e) {
            console.log(e);
        }

        setTreasuryValue("0");
        return
    }

    async function getLFVValue() {
        try {
            let lfv = await SCM.liquidityFloorValueFund();
            let liqBNB = await provider.getBalance(lfv);
            setLfvValue(ethers.utils.formatEther(liqBNB.mul(bnbUSD)));
            return;
        } catch(e) {
            console.log(e);
        }

        getLFVValue("0");
        return
    }

    useEffect(() => {
        if (price !== undefined && circulatingSupply !== undefined) {
            setMarketCap(circulatingSupply.toNumber() * price)
        }
    }, [price, circulatingSupply]);

    useEffect(() => {
        if (bnbUSD !== undefined) {           
            (async function () {
                getBNBLiquidityValue();
                getTreasuryValue();
                getLFVValue();
            })()            
        }
    }, [bnbUSD])
    
    async function update() {
        if (SCM) {
            await Promise.all([
                getPrice(),
                getBNBUSD(),
                getCirculatingSupply(),
                getLastRebaseTime(),
                getRebaseRate(),
                getLiquidityBacking(),               
            ])
        }
    }    

    useEffect(() => {
        if (provider && SCM) {
            update();
            provider.on("block", _ => {                
                update();
            })
            return () => provider.off("block");
        }
    }, [provider, SCM]);

    useClock(() => {
        if (!lastRebaseTime || !rebaseRate) 
            return;

        let now = Math.floor(Date.now() / 1000);
        let timeSinceLastRebase = ethers.BigNumber.from(now).sub(lastRebaseTime)
        let secondsSince = timeSinceLastRebase.mod(rebaseRate);
        let timeUntilNextRebase = rebaseRate.sub(secondsSince);
        setTimeUntilRebase(timeUntilNextRebase.toNumber())
    })

    return [
        price,
        priceChange,
        circulatingSupply,
        marketCap,
        lastRebaseTime,
        backedLiquidity,
        bnbLiquidityValue,
        bnbLiquidityValueChange,
        treasuryValue,
        treasuryValueChange,
        lfvValue,
        lfvValueChange,
        timeUntilRebase
    ]
}