import { ethers } from "ethers";
import React, { useState } from "react";
import { formatNumber, formatSeconds } from "../utils";

export default function Acccount ({
    usdEarned,
    scmEarned,
    apy,
    balance,
    dailyEarnings,
    timeUntilRebase
}) {
    return (
        <>
            <div id="w-node-_8aa8924f-d6e6-432d-5125-fc761c2ce669-12e17434" className="dashboard-content">
            <div className="block dashboard">
                <div className="container w-container">
                <div className="body-p dahsboard">Total earned</div>
                <div className="hero-h1 dashboard">${usdEarned ? formatNumber(ethers.utils.formatUnits(usdEarned, 5)) : "0.0000"}<br/></div>
                <div className="body-p dahsboard">{scmEarned ? formatNumber(ethers.utils.formatUnits(scmEarned, 5)) : "0.00"} SCM (+{apy ? apy.toString() : "0.00"}%)<br/></div>
                </div>
            </div>
            <div className="w-layout-grid grid account">
                <div id="w-node-dae24979-9db1-bed6-2d01-b2d0851ac3dd-12e17434" className="block dashboard">
                <div className="w-row">
                    <div className="w-col w-col-6">
                    <div className="container w-container">
                        <div className="body-p dahsboard">Return</div>
                        <div className="body-h4">{apy ? apy.toString() : "0.00"}%<br/></div>
                        <div className="body-p dahsboard">Daily ROI 2.15%<br/></div>
                    </div>
                    </div>
                    <div className="w-col w-col-6">
                    <div className="container w-container">
                        <div className="body-p dahsboard">Your Balance<br/></div>
                        <div className="body-h4">{balance ? formatNumber(ethers.utils.formatUnits(balance, 5)) : "0.0000"}<br/></div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="block dashboard">
                <div className="w-row">
                    <div className="w-col w-col-6">
                    <div className="container w-container">
                        <div className="body-p dahsboard">Next Rebase:</div>
                        <div className="body-h4">{timeUntilRebase ? formatSeconds(timeUntilRebase) : "00:00:00"}<br/></div>
                        <div className="body-p dahsboard">Interest Coming In Your Wallet<br/></div>
                    </div>
                    </div>
                    <div className="w-col w-col-6">
                    <div className="container w-container">
                        <div className="body-p dahsboard">Your Earnings/Daily<br/></div>
                        <div className="body-h4">${dailyEarnings ? formatNumber(
                            ethers.utils.formatUnits(dailyEarnings, 5)) : "0.0000"}<br/></div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </>        
    )
}