import React, { useCallback, useState } from "react";
import useWeb3 from "../hooks/useWeb3";

import Dashboard from "../sections/dashboard";
import Acccount from "../sections/account";
import Calculator from "../sections/calculator";

import useSCMInfo from "../hooks/useSCMInfo";
import useUserInfo from "../hooks/useUserInfo";
import useCalculator from "../hooks/useCalculator";

import "./index.css"

export default function Index () {
    /** WEB 3 */
    const [
        provider,
        signer,
        address,
        contractSCM,
        interfaceSCM,
        contractWBNB,
        isConnected,
        isCorrectChain,        
        connect,
        isConnecting,
      ] = useWeb3();

    const [
        price,
        priceChange,
        circulatingSupply,
        marketCap,
        lastRebaseTime,
        backedLiquidity,
        bnbLiquidityValue,
        bnbLiquidityValueChange,
        treasuryValue,
        treasuryValueChange,
        lfvValue,
        lfvValueChange,
        timeUntilRebase
    ] = useSCMInfo(contractSCM, contractWBNB, provider, address);

    const [
        usdEarned,
        scmEarned,
        apy,
        balance,
        dailyEarnings
    ] = useUserInfo(contractSCM, interfaceSCM, provider, address, price);

    const [
        initial,
        current,
        earned,
        c_apy,
        calculateScenario
    ] = useCalculator()

    const [showDashboard, setShowDashboard] = useState(true);
    const [showAccount, setShowAccount] = useState(false);
    const [showCalculator, setShowCalculator] = useState(false);

    const handleNav = (option) => {
        switch (option) {
            case 1:                
                setShowAccount(false)
                setShowCalculator(false)
                setShowDashboard(true);
                break;
            case 2:                
                setShowCalculator(false)
                setShowDashboard(false);
                setShowAccount(true)
                break;
            case 3:
                setShowDashboard(false);
                setShowAccount(false)
                setShowCalculator(true)                
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div data-animation="over-right" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" data-doc-height="1" role="banner" className="navbar-light dashboard w-nav">
                <div className="container-light-nav w-container">
                <a href="#" className="navbar-light-brand w-nav-brand">
                    <div className="company-name-block"></div>
                    <img src="images/Logo.svg" loading="eager" alt="Logo Scorpion Capital Management" className="image-5"/>
                </a>
                <nav role="navigation" className="nav-menu w-nav-menu">
                    <a href="index.html" className="navbar-light-bold-link w-nav-link">Home</a>
                    <link rel="prefetch" href="/"/>
                    <div className="sidebar mobile">

                    <span onClick={() => handleNav(1)} aria-current="page" className="sidebar-menuitem link-block w-inline-block w--current">
                        <img src="images/Icon_Liquidity-Floor-Value.svg" loading="lazy" width="15" alt="Liquidity Floor Value" className="image-11"/>
                        <h1 className="sidebar-menu-item">Dashboard</h1>
                    </span>

                    <link rel="prerender" href="#"/>

                    <span onClick={() => handleNav(2)} data-w-id="2467eca9-15b4-4571-04b5-67db4d984d33" className="sidebar-menuitem w-inline-block">
                        <img src="images/iconmonstr-user-19.svg" loading="lazy" width="15" alt="" className="image-11"/>
                        <h1 className="sidebar-menu-item">Account</h1>
                    </span>

                    <span onClick={() => handleNav(3)} data-w-id="2467eca9-15b4-4571-04b5-67db4d984d33" className="sidebar-menuitem w-inline-block">
                        <img src="images/iconmonstr-calculator-1.svg" loading="lazy" width="15" alt="" className="image-11"/>
                        <h1 className="sidebar-menu-item">Calculator</h1>
                    </span>

                    <link rel="prerender" href="#"/>

                    <a data-w-id="2467eca9-15b4-4571-04b5-67db4d984d33" href="https://pancakeswap.finance/swap?outputCurrency=0xD0f30CdB663656bbDc57f4C71964FD44966D59FA" target="_blank" className="sidebar-menuitem w-inline-block">
                        <img src="images/iconmonstr-connection-7.svg" loading="lazy" width="15" alt="" className="image-11"/>
                        <h1 className="sidebar-menu-item">Swap</h1>
                    </a>
                    <a data-w-id="2467eca9-15b4-4571-04b5-67db4d984d33" href="https://docs.scm.finance/" target="_blank" className="sidebar-menuitem w-inline-block">
                        <img src="images/iconmonstr-book-1.svg" loading="lazy" width="15" alt="" className="image-11"/>
                        <h1 className="sidebar-menu-item">Docs</h1>
                    </a>

                    <link rel="prefetch" href="https://docs.scm.finance/"/>

                    </div>

                    {
                        !isConnected &&
                        <span onClick={connect} className="nav-btn w-button">Connect wallet</span>
                    }

                    {
                        isConnected &&
                        <span className="nav-btn w-button">{address.slice(0, 15)}...</span>
                    }
                    
                </nav>
                <div className="menu-button w-nav-button">
                    <div className="navbar-light-icon w-icon-nav-menu"></div>
                </div>
                </div>
            </div>
        
        <div data-poster-url="videos/eart_line-poster-00001.jpg" data-video-urls="videos/eart_line-transcode.mp4,videos/eart_line-transcode.webm" data-autoplay="true" data-loop="true" data-wf-ignore="true" className="background-video-2 w-background-video w-background-video-atom">
            <video autoPlay loop="" 
                style={{backgroundImage: "url(&quot;videos/eart_line-poster-00001.jpg&quot;)"}} 
                muted="" playsInline data-wf-ignore="true" data-object-fit="cover">
            <source src="videos/eart_line-transcode.mp4" data-wf-ignore="true"/>
            <source src="videos/eart_line-transcode.webm" data-wf-ignore="true"/>
            </video>
            <div id="w-node-_3e8378d7-229d-639e-af95-c46b6bcd0970-c6421247" className="sidebar">

                <span onClick={() => handleNav(1)} aria-current="page"
                    className={`sidebar-menuitem w-inline-block ${showDashboard ? "link-block w--current" : null}`}>
                    <img src="images/Icon_Liquidity-Floor-Value.svg" loading="lazy" width="15" alt="Liquidity Floor Value" className="image-11"/>
                    <h1 className="sidebar-menu-item">Dashboard</h1>
                </span>

                <link rel="prerender" href="#"/>

                <span onClick={() => handleNav(2)} data-w-id="2467eca9-15b4-4571-04b5-67db4d984d33" 
                    className={`sidebar-menuitem w-inline-block ${showAccount ? "link-block w--current" : null}`}>
                    <img src="images/iconmonstr-user-19.svg" loading="lazy" width="15" alt="" className="image-11"/>
                    <h1 className="sidebar-menu-item">Account</h1>
                </span>

                <span onClick={() => handleNav(3)} data-w-id="2467eca9-15b4-4571-04b5-67db4d984d33"
                     className={`sidebar-menuitem w-inline-block ${showCalculator ? "link-block w--current" : null}`}>
                    <img src="images/iconmonstr-calculator-1.svg" loading="lazy" width="15" alt="" className="image-11"/>
                    <h1 className="sidebar-menu-item">Calculator</h1>
                </span>

                <link rel="prerender" href="#"/>

                <a data-w-id="2467eca9-15b4-4571-04b5-67db4d984d33" href="https://pancakeswap.finance/swap?outputCurrency=0xD0f30CdB663656bbDc57f4C71964FD44966D59FA" target="_blank" className="sidebar-menuitem w-inline-block">
                    <img src="images/iconmonstr-connection-7.svg" loading="lazy" width="15" alt="" className="image-11"/>
                    <h1 className="sidebar-menu-item">Swap</h1>
                </a>
                <a data-w-id="2467eca9-15b4-4571-04b5-67db4d984d33" href="https://docs.scm.finance/" target="_blank" className="sidebar-menuitem w-inline-block">
                    <img src="images/iconmonstr-book-1.svg" loading="lazy" width="15" alt="" className="image-11"/>
                    <h1 className="sidebar-menu-item">Docs</h1>
                </a>
                <link rel="prefetch" href="https://docs.scm.finance/"/>
            </div>

            {
                showDashboard &&
                <Dashboard 
                    price={price}
                    priceChange={priceChange}
                    circulatingSupply={circulatingSupply}
                    marketCap={marketCap}
                    lastRebaseTime={lastRebaseTime}
                    backedLiquidity={backedLiquidity}
                    bnbLiquidityValue={bnbLiquidityValue}
                    bnbLiquidityValueChange={bnbLiquidityValueChange}
                    treasuryValue={treasuryValue}
                    treasuryValueChange={treasuryValueChange}
                    lfvValue={lfvValue}
                    lfvValueChange={lfvValueChange}
                    timeUntilRebase={timeUntilRebase}
                />
            }
            {
                showAccount &&
                <Acccount 
                    usdEarned={usdEarned}
                    scmEarned={scmEarned}
                    apy={apy}
                    balance={balance}
                    dailyEarnings={dailyEarnings}
                    timeUntilRebase={timeUntilRebase}
                />
            }
            {
                showCalculator &&
                <Calculator
                    price={price}
                    balance={balance}
                    initial={initial}
                    current={current}
                    earned={earned}
                    c_apy={c_apy}
                    calculateScenario={calculateScenario}
                />
            }

        </div>
        </>
        
    )
}