const Config = {
    DATA: {
        MINT_START: 0
    },
    CONTRACTS: {
        SCM: "SCM",
        EXTERNAL: {
            WBNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
            LP: "0x2cEaC0a94b2F7B348C8Db97d16b6a6527f844369"
        }
    },
    NETWORK: {
        TARGET_CHAIN_ID: 56,
        CHAIN_ID_TO_NAME: {
            "1": "mainnet",
            "3": "ropsten",
            "4": "rinkeby",
            "56": "bsc-mainnet"
        },
        INFURA_ID: "69cea975d1444c3f8ebec639ad390894",
        PORTIS_ID: "",
        FORTMATIC_ID: ""
    },
    MORALIS: {
        CREDS: [
            {
                SERVER_URL: "https://uritm73gzkfu.usemoralis.com:2053/server",
                APP_ID: "ECBOG5Km9gRJr2qPtcsK9pfy8VC3d8zaZEdhP6RD"                
            },
            {
                SERVER_URL: "https://nvfpck3pfurc.usemoralis.com:2053/server",
                APP_ID: "NCnyLeWhz3lxb0NVmGBoQSIMBv3PZ3Izre1UeYAD"                
            },
            {
                SERVER_URL: "https://b7aejlkqx6hg.usemoralis.com:2053/server",
                APP_ID: "UFHjI5eeg95HEZL06bu2qXCv5hkILb4597YgH2Cv"                
            }         
        ],        
        TABLE_NAMES: {
            Transfers: "Transfers",
        },
        TABLE_COLUMNS: {
            Buys: {
                to: "to",
                value: "value"
            },
            Sells: {
                from: "from",
                value: "value"
            }
        }
    },
    ERRORS: {
        MESSAGES: [
            {
                error: "HAVE TO BUY AT LEAST 1",
                message: "Error: Your transaction has not gone through. You have to buy at least one ticket"
            },
            {
                error: "MetaMask Tx Signature: User denied transaction signature",
                message: "Error: You rejected the transaction."
            },
            {
                error: "insufficient funds for gas * price + value",
                message: "Error: You don't have enough Ether in your wallet to complete the transaction. The price for one is [amount] Ether.",
                insert: true
            },
            {
                error: "INVALID PROOF",
                message: "Error: You are not on the white list. Please contact the team if you think this is incorrect."
            }
        ]
    }
}

export default Config